import {DependencyList, useEffect} from "react";
import * as NativeUtil from "utils/native_util";

type HeadlessCallback = (headless: boolean) => void;

export const useHeadless = (callback: HeadlessCallback) => {
  useEffect(() => {
    window.reactHeadlessCallback = callback;
    NativeUtil.reactHeadlessReady().then((res: boolean) => {
      if (window.reactHeadlessCallback) {
        window.reactHeadlessCallback(res);
      }
    });
    return () => {
      window.reactHeadlessCallback = undefined;
    };
  }, []);
};

window._backCallbackFunctions = {
  globalArea: undefined,
  stackArea: []
};

window.reactNativeBackCallback = async () => {
  // false 일경우, webview 닫음, true 일경우 webview 안닫음
  // 여러개의 view 에서 Hook 을 쓸 수 있다.
  // 처리를 위해 두개의 구조로 진행한다, global과 stack 을 두고, global 호출, stack 의 top 함수호출 해서 결과를 전달

  const globalFunc = window._backCallbackFunctions.globalArea ?? (() => Promise.resolve(false));

  const stackAreaLength = window._backCallbackFunctions.stackArea?.length ?? 1;
  const localFunc = (window._backCallbackFunctions.stackArea ?? [])[stackAreaLength - 1] ?? (() => Promise.resolve(false));

  try {
    if(await globalFunc()) {
      return true;
    }
    else {
      return await localFunc();
    }
  } catch (e) {
    return false;
  }
};

export const useNativeBack = (scope: 'global' | 'stack', callback: NativeCallback, deps: DependencyList) => {
  // 주의, scope 가 stack 인 경우, deps 를 무시한다.
  // 사유 - deps 를 추가할 경우, deps 값이 변경되면서 stackArea 가 꼬일 수 있음
  const dependencyArray = (scope === 'global') ? deps : [];
  useEffect(() => {
    if(scope === 'global') {
      window._backCallbackFunctions.globalArea = callback;
      return () => {
        window._backCallbackFunctions.globalArea = undefined;
      };
    }
    else if(scope === 'stack') {
      window._backCallbackFunctions.stackArea.push(callback);
      return () => {
        window._backCallbackFunctions.stackArea.pop();
      };
    }
  }, dependencyArray);
};
