import * as React from 'react';
import {Suspense, useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import MemberShipRoute from './routes/membership_route';
import HelpRoute from './routes/help_route';
import GuaranteeRoute from './routes/guarantee_route';
import LinkView from "./views/link/link_view";
import EventRoute from "./routes/event_route";
import LgUPlusSubscriptionRoute from "./routes/lguplus_subscription_route";
import VerificationRoute from './routes/verification_route';
import PaymentRoute from "./routes/payment_route";
import GsPayShareRoute from "./routes/gspay_share_route";
import GiftCardRoute from "./routes/gift_card_route";
import PostboxRoute from "./routes/postbox_route";
import Spinner from "views/common/spinner";

const Redirect = ({url}) => {
  useEffect(() => {
    window.location.href = url;
  }, []);

  return null;
};

const SuspenseWrapper = ({children}) => {
  return <Suspense fallback={<Spinner/>} >
    {children}
  </Suspense>
}

function App() {
  useEffect(() => {
    console.log(`env=${process.env.REACT_APP_ENV}`);
  }, []);
  return (
    <div>
      <Routes>
        <Route path="home" element={<SuspenseWrapper><Redirect url='https://gs25.gsretail.com/gscvs/ko/store-services/woodongs'/></SuspenseWrapper>}/>
        <Route path="link" element={<SuspenseWrapper><LinkView/></SuspenseWrapper>}/>
        <Route path="membership/*" element={<SuspenseWrapper><MemberShipRoute/></SuspenseWrapper>}/>
        <Route path="help/*" element={<SuspenseWrapper><HelpRoute/></SuspenseWrapper>}/>
        <Route path="guarantee/*" element={<SuspenseWrapper><GuaranteeRoute/></SuspenseWrapper>}/>
        <Route path="event/*" element={<SuspenseWrapper><EventRoute/></SuspenseWrapper>}/>
        <Route path="lguplus_subscription/*" element={<SuspenseWrapper><LgUPlusSubscriptionRoute/></SuspenseWrapper>}/>
        <Route path="verification/*" element={<SuspenseWrapper><VerificationRoute/></SuspenseWrapper>}/>
        <Route path="gspay_share/*" element={<SuspenseWrapper><GsPayShareRoute/></SuspenseWrapper>}/>
        <Route path="gift_card/*" element={<SuspenseWrapper><GiftCardRoute/></SuspenseWrapper>}/>
        <Route path="postbox/*" element={<SuspenseWrapper><PostboxRoute/></SuspenseWrapper>}/>

        {/*주문서에서는 Suspense fallback 렌더링 안되도록 수정*/}
        <Route path="payment/*" element={<PaymentRoute/>}/>
      </Routes>
    </div>
  );
}

export default App;
