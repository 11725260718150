import Loader from "react-loader-spinner";
import * as React from 'react';
import {useEffect} from "react";
import {hideProgress, showProgress} from "utils/native_util";


const Spinner = () => {

  useEffect(() => {
    showProgress();

    return () => {
      hideProgress();
    }
  }, [])

  return <></>;
};

export default Spinner;
