import * as React from 'react';
import {lazy} from 'react';
import {Route, Routes} from 'react-router-dom';
import * as EnvUtils from "../utils/env_util";

const Kakao26WeekEvent = lazy(() => import('../views/event/kakao/kakao26WeekEvent'));
const IntegratedRoyaltyEvent01 = lazy(() => import('../views/event/membership/integrated_royalty_event01'));
const IntegratedRoyaltyEvent02 = lazy(() => import('../views/event/membership/integrated_royalty_event02'));
const EventApplyInfo = lazy(() => import('../views/event/thepop/musicBeerFestival/event_apply_info'));
const EventTicketArrival = lazy(() => import('../views/event/thepop/musicBeerFestival/event_ticket_arrival'));
const FamilyMembershipEvent = lazy(() => import('../views/event/membership/family_membership_event'));
const MemberWithMeEvent = lazy(() => import('../views/event/membership/202307_member_with_me'));
const BingoEvent202309 = lazy(() => import('../views/event/membership/202309_bingo_event'));
const IntegratedEvent202405 = lazy(() => import('views/event/membership/202405_integrated_event/202405_integrated_event'));
const IntegratedEvent202405Input = lazy(() => import('views/event/membership/202405_integrated_event/202405_integrated_event_input'));
const PyunStoRang = lazy(() => import('../v2/views/event/gs25/pyunStoRang/PyunStoRangMain'));
const GsAttendance = lazy(() => import('../v2/views/event/gs25/attendance/AttendanceMain'));
const GsPayIntro = lazy(() => import('../v2/views/event/gs25/gspay-intro/GsPayIntroMain'));
const SuperGiftDelivery = lazy(() => import('../v2/views/event/super/giftDelivery/GiftDeliveryMain'));
const SuperGiftDeliveryComplete = lazy(() => import('../v2/views/event/super/giftDelivery/GiftDeliveryComplete'));
const SuperGiftInit = lazy(() => import('../v2/views/event/super/giftDelivery/GiftDeliveryInit'));
const GsAllPoint = lazy(() => import('../v2/views/event/gs25/gsAllPoint/event_gsAll_point_main'))
const GSGiftDelivery202407 = lazy(() => import('../v2/views/event/gs25/giftDelivery_202407/GiftDelivery202407Main'));
const GsDonation = lazy(() => import('../v2/views/event/gs25/donation/event_gs_donation_main'));
const GsSurvey = lazy(() => import('../v2/views/event/gs25/survey/event_gs_survey_main'))

const EventRoute = () => {
    let integratedRoyaltyEvent01EventCode = '';
    let integratedRoyaltyEvent02EventCode = '';
    let familyMembershipEventCode = '';
    let memberWithMeEventCode = '';
    let bingoEvent202309Code = '';

    if (EnvUtils.isProduction()) {
        integratedRoyaltyEvent01EventCode = '2023020012';
        integratedRoyaltyEvent02EventCode = '2023020013';
        familyMembershipEventCode = '2023050009';
        memberWithMeEventCode = '2023060007';
        bingoEvent202309Code = '2023080002';
    } else {
        integratedRoyaltyEvent01EventCode = '2023020005';
        integratedRoyaltyEvent02EventCode = '2023020006';
        familyMembershipEventCode = '2023050006';
        memberWithMeEventCode = '2023060003';
        bingoEvent202309Code = '2023080003';
    }

    return (
        <Routes>
            <Route path="kakao/26week" element={<Kakao26WeekEvent/>}/>
            <Route path={`royalty/membership/${integratedRoyaltyEvent01EventCode}`} element={<IntegratedRoyaltyEvent01/>}/>
            <Route path={`royalty/membership/${integratedRoyaltyEvent02EventCode}`} element={<IntegratedRoyaltyEvent02/>}/>
            <Route path="festival/apply/info" element={<EventApplyInfo/>}/>
            <Route path="festival/ticket" element={<EventTicketArrival/>}/>
            <Route path={`royalty/membership/${familyMembershipEventCode}`} element={<FamilyMembershipEvent/>}/>
            <Route path={`royalty/membership/${memberWithMeEventCode}`} element={<MemberWithMeEvent/>}/>
            <Route path={`royalty/membership/${bingoEvent202309Code}`} element={<BingoEvent202309/>}/>
            <Route path={`royalty/membership/202405`} element={<IntegratedEvent202405/>}/>
            <Route path={`royalty/membership/202405-address`} element={<IntegratedEvent202405Input/>}/>
            <Route path="gs/pyunstorang" element={<PyunStoRang/>}/>
            <Route path="gs/attendance" element={<GsAttendance/>} />
            <Route path="gs/gspayintro" element={<GsPayIntro/>} />
            <Route path={`super/gift-delivery`} element={<SuperGiftDelivery/>} />
            <Route path={`super/gift-delivery-complete`} element={<SuperGiftDeliveryComplete/>} />
            <Route path={`super/gift-delivery-init`} element={<SuperGiftInit/>} />
            <Route path={`gs/gift-delivery-202407`} element={<GSGiftDelivery202407/>} />
            <Route path={`gs/donation`} element={<GsDonation/>} />
            <Route path={`gs/survey`} element={<GsSurvey/>} />
            <Route path={`gs/gsAllPoint`} element={<GsAllPoint/>} />
        </Routes>
    );
};

export default EventRoute;
