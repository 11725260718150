import * as React from 'react';
import {lazy} from 'react';
import {Route, Routes} from 'react-router-dom';
import AuthRoute from "./auth_route";

const HelpMain = lazy(() => import('../views/help/help_main'));
const HelpNumbers = lazy(() => import('../views/help/help_number'));
const NoticeList = lazy(() => import('../views/help/notice_list'));
const NoticeDetail = lazy(() => import('../views/help/notice_detail'));
const NoticeStoryDetail = lazy(() => import('../views/help/notice_story_detail'));
const FAQ = lazy(() => import('../views/help/faq'));
const FAQSearch = lazy(() => import('../views/help/faq_search'));
const Guide = lazy(() => import('../views/help/guide'));
const Gs25Guide = lazy(() => import('../views/help/guide_gs25'));
const GsSuperGuide = lazy(() => import('../views/help/guide_gs_super'));
const InquiryList = lazy(() => import('../views/help/inquiry_list'));
const InquiryWrite = lazy(() => import('../views/help/inquiry_write'));
const InquiryDetail = lazy(() => import('../views/help/inquiry_detail'));
const InquiryMyOrders = lazy(() => import('../views/help/inquiry_my_orders'));
const InquirySelectOrder = lazy(() => import('../views/help/inquiry_select_order'));
const InquiryPostboxOrder = lazy(() => import('../views/help/inquiry_postbox_order'));
const DynamicInquiryWrite = lazy(() => import('../views/help/dynamic_inquiry_write'));



const TermsMain = lazy(() => import('../views/help/terms_main'));
const TermsDetail = lazy(() => import('../views/help/terms_detail'));
const TermDetail2 = lazy(() => import('../v2/views/terms/terms_main'));
const TermsDetailGsPoint = lazy(() => import('../views/help/terms_detail_gspoint'));
const TermsDetailPush = lazy(() => import('../views/help/terms_detail_push'));
const TermsDetailCaltex = lazy(() => import('../views/help/terms_detail_caltex'));
const KidMealTermsDetail = lazy(() => import('../views/help/terms_detail_kidMeal'));
const TermsDetailGs25UnmannedStore = lazy(() => import('../views/help/terms_detail_gs25_unmanned_store'));
const MemberTermsDetail = lazy(() => import('../views/help/terms_detail_member'));
const Gs25DeliveryDelayNotification = lazy(() => import('../v2/views/help/gs25/delivery_delay_notification/delivery_delay_notification_main'));

const HelpRoute = () => {
  return (
    <Routes>
      <Route path="main" element={<HelpMain/>}/>
      <Route path="help_number" element={<HelpNumbers/>}/>
      <Route path="notice" element={<NoticeList/>}/>
      <Route path="notice_detail" element={<NoticeDetail/>}/>
      <Route path="notice_story_detail" element={<NoticeStoryDetail/>}/>
      <Route path="faq" element={<FAQ/>}/>
      <Route path="faq_search" element={<FAQSearch/>}/>
      <Route path="guide" element={<Guide/>}/>
      <Route path="guide/gs25/:index" element={<Gs25Guide/>}/>
      <Route path="guide/gs_super/:index" element={<GsSuperGuide/>}/>
      <Route path="inquiry" element={<AuthRoute element={<InquiryList/>}/>}/>
      <Route path="inquiry_write" element={<DynamicInquiryWrite/>}/>
      <Route path="inquiry_detail/:inquiryDate/:inquirySeqno/" element={<InquiryDetail/>}/>
      <Route path="inquiry_my_orders" element={<InquiryMyOrders/>}/>
      <Route path="inquiry_select_order" element={<InquirySelectOrder/>}/>
      <Route path="inquiry_postbox_order" element={<InquiryPostboxOrder/>}/>

      {/*약관*/}
      <Route path="terms" element={<TermsMain />} />
      <Route path="terms/detail/:code" element={<TermsDetail />} />
      <Route path="terms/detail/v2/:code" element={<TermDetail2/>} />
      <Route path="terms/detail_gspoint" element={<TermsDetailGsPoint />} />
      <Route path="terms/detail_push/:type" element={<TermsDetailPush/>} />
      <Route path="terms/detail_caltex" element={<TermsDetailCaltex/>} />
      <Route path="terms/detail_kidmeal" element={<KidMealTermsDetail/>} />
      <Route path="terms/detail_gs25_unmanned_store" element={<TermsDetailGs25UnmannedStore/>} />
      <Route path="terms/detail/member" element={<MemberTermsDetail/>} />

      {/*gs25*/}
      <Route path="gs25/delivery_delay_notification" element={<Gs25DeliveryDelayNotification/>} />
    </Routes>
  );
};

export default HelpRoute;
